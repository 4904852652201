.page {
    box-sizing: border-box;
    padding: 8px 12px;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    position: relative;
    font-size: 2.4rem;
    margin-top: 12vh;
    position: relative;
}

.container {
    margin: 18px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.subtitle {
    margin: 14px 0;
}

.content h3 {
    color: #973AA8;
}

.card h3 {
    font-size: 0.8rem;
    color: #973AA8;
    margin: 4px 0;
}

.card p {
    color: #10002b;
    font-size: 16px;
    margin: 4px 0;

}

.card {
    background: #fff;
    border-radius: 8px;
    width: 35vw;
    height: 110px;
    padding: 18px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}    

.card img {
    object-fit: scale-down;
    height: 40px;
    max-width: 25vw;
}

.tag {
    font-size: 1.2rem;
}

.wrapper {
    width: 90vw;
    margin-bottom: 24px;
}

.wrapper img {
    width: 100%;
}

.wrapper h2 {
    color: #973AA8;

}

@media only screen and (min-width: 768px) {
    .title {
        margin-top: 14vh;
    }
    
    .card {
        width: 20vw;
        padding: 22px 12px;
        height: 180px;
    }

    .card img {
        max-width: 15vw;
    }

    .card h3 {
        font-size: 1rem;
        margin: 8px 0;
    }

    .container {
        gap: 24px;
    }
    .wrapper {
        width: 80vw;
    }
}


@media only screen and (min-width: 1024px) {
    .card {
        width: 15vw;
    }

    .card img {
        max-width: 10vw;
    }

    .card h3 {
        font-size: 1.2rem;
        margin: 12px 0;
    }
    .container {
        gap: 36px;
    }
    .subtitle {
        font-size: 1.4rem;
    }
    .wrapper {
        width: 60vw;
    }
}