.component {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: #10002b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    overflow: hidden;
}

.component h2 {
    font-family: "Mars";
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    aspect-ratio: 1;
    color: #f03355;
    position: relative;
    background: radial-gradient(10px,currentColor 94%,#0000);
  }
  .loader:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background:
      radial-gradient(9px at bottom right,#0000 94%,currentColor) top    left,
      radial-gradient(9px at bottom left ,#0000 94%,currentColor) top    right,
      radial-gradient(9px at top    right,#0000 94%,currentColor) bottom left,
      radial-gradient(9px at top    left ,#0000 94%,currentColor) bottom right;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    animation: l18 1.5s infinite cubic-bezier(0.3,1,0,1);
  }
  @keyframes l18 {
     33%  {inset:-10px;transform: rotate(0deg)}
     66%  {inset:-10px;transform: rotate(90deg)}
     100% {inset:0    ;transform: rotate(90deg)}
  }