

.title {
    font-size: 1.4rem;
}

.banner {
    width: 100%;
    height: 45vh;
    background: rgb(213, 93, 146);
    background: linear-gradient(117deg, rgba(213, 93, 146, 1) 7%, rgba(109, 35, 182, 1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6vh;
    gap: 20px;
}

.banner img {
    height: 20vh;
}

.header {
    text-align: center;
}

.header p {
    margin: 6px 0;
    font-size: 1.1rem;
}

.container {
    margin: 32px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.card {
    background: rgba(246, 221, 255, 0.118);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.3px);
    -webkit-backdrop-filter: blur(2.3px);
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15vh;
    box-sizing: border-box;
    padding: 12px 18px;
}

.left {
    height: 70%;
    display: flex;
    align-items: center;
    gap: 12px;
}

.left img {
    height: 80%;
}

.name {
    font-size: 0.8rem;
    text-align: left;
}

.right h3 {
    font-size: 0.8rem;
}

@media only screen and (min-width: 768px) {
    .banner {
        flex-direction: row;
        height: 35vh;
        gap: 24px;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 4vh;
        padding-top: 0;
    }

    .banner img {
        height: 20vh;
    }

    .header {
        text-align: left;
    }

    .title {
        font-size: 2rem;
    }

    .header h3 {
        font-size: 1.8rem;
    }

    .header p {
        font-size: 1.6rem
    }

    .name {
        font-size: 1.4rem;
    }

    .left {
        gap: 24px;
    }

    .left img {
        height: 100%;
    }
    
    .right h3 {
        font-size: 1.4rem;
    }

    .card {
        padding: 12px 20px;
    }
}


@media only screen and (min-width: 1024px) {
    .banner {
        height: 40vh;
        gap: 36px;
    }

    .banner img {
        height: 30vh;
    }

    .title {
        font-size: 2rem;
    }

    .header h3 {
        font-size: 1.4rem;
    }

    .header p {
        font-size: 1.2rem;
    }

    .card {
        padding: 2px 24px;
    }

    .name {
        font-size: 1.2rem;
    }
}