.App {
  text-align: center;
}

h1 {
  font-size: 6rem;
  margin: 4px 0;
}

h3 {
  font-family: 'Polly Rounded';
  margin: 2px 0;
  font-size: 1.2rem;
}

.star {
  width: 16vw;
  filter: drop-shadow(0px 12px 7px rgba(116, 36, 255, 0.5));
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}


