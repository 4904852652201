.component {
    padding: 48px 24px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 36px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: 40vh;
    cursor: pointer;
    transition: 0.4s;
}

.card:hover {
    background-color: #C05299;
}

.card img {
    width: 100%;
    height: 65%;
    object-fit: cover;
}

.card h2 {
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;

}

.card a {
    text-decoration: none;
    margin: 0;
    width: 100%;
    height: 100%;
}

.link {
    text-decoration: none;


    .title {
        position: relative;
        font-size: 2.4rem;
        margin-top: 100px;
        position: relative;
    }
}

@media only screen and (min-width: 768px) {
    .card {
        width: 40vw;
        height: 30vh;
    }

    .container {
        gap: 48px;
    }
}


@media only screen and (min-width: 1024px) {
    .card {
        width: 20vw;
        height: 45vh;
    }

    .container {
        width: 80%;
        gap: 84px;
    }

}