
@font-face {
  font-family: 'Good Times Rg';
  src: url('./assets/fonts/GoodTimesRg-Regular.woff2') format('woff2'),
      url('./assets/fonts/GoodTimesRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Polly Rounded';
  src: url('./assets/fonts/PollyRounded-Bold.woff2') format('woff2'),
      url('./assets/fonts/PollyRounded-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mars';
  src: url('./assets/fonts/MarsRegular.woff2') format('woff2'),
      url('./assets/fonts/MarsRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  background-color: #10002b;
  color: #fff;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1 {
  font-family: 'Mars';

}

body {
  margin: 0;
  font-family: 'Polly Rounded';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-16 14:15:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 :global {
  @keyframes puffOutCenter {
      0% {
          transform: scale(1);
          filter: blur(0);
          opacity: 1
      }

      100% {
          transform: scale(2);
          filter: blur(4px);
          opacity: 0
      }
  }
}

