.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    box-sizing: border-box;
    padding: 24px 28px;;
}

.bg {
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    background-size: cover;
    z-index: -5;
    background: url(../assets/bg3.gif) 100% 100% no-repeat;
    background-size: cover;
    background-position: top center;
    opacity: 0.4;

}
