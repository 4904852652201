.main {
    margin: 24px 0;
}

.container {
    border: 2px solid #fff;
    padding: 6px 18px;
    box-shadow:
        12px 8px 0 0px rgba(0,0,0,0.5),
        14px 6px 0 0px #c05299,
        14px 10px 0 0px #c05299,
        10px 10px 0 0px #c05299;
}

.h1 {
    font-family: Polly Rounded;
    color: #EA698B;
    font-size: 2.2rem;
}

.note {
    font-size: 1rem;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

@media only screen and (min-width: 768px) {
    .h1 {
        font-size: 4.8rem;
    }

    .p {
        font-size: 1.8rem;
    }

    .note {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 1024px) {
    .h1 {
        font-size: 3rem;
    }

    .p {
        font-size: 1rem;
    }

    .note {
        font-size: 1.6rem;
    }
}