.component {
    justify-self: flex-start;
}

.title {
    font-size: 1.4rem;
    margin: 24px 0;
}

.title span {
    color: #ff225d;
}

.component button {
    width: 80vw;
    box-sizing: border-box;
    padding: 12px 24px;
    font-size: 1rem;
    margin: 12px 0;
    cursor: pointer;
    font-family: "Polly Rounded";
}

.retry {
    background: none;
    color: #fff;
    border: 2px solid #fff;
}

.share {
    background: #EA698B;
    color: #fff;
    border: none;

}

.card {
    width: 90vw;
    margin-top: 40px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.share a {
    text-decoration: none;
    color: #fff;

}
@media only screen and (min-width: 768px) {
 .title {
    font-size: 2rem;
    margin: 32px 0;
 }
 .card {
    width: 80vw;
 }
}

@media only screen and (min-width: 1024px) {
    .component {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 84px;
    }
    .card {
        width: 24vw;
    }
    .component button {
        width: 24vw;
    }
}