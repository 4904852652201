    .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    position: relative;
    font-size: 2.4rem;
    margin-top: 100px;
    position: relative;
}


.tag {
    font-size: 1.2rem;
}

.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 32px 0;
}

.card {
    width: 70vw;
}

.card img {
    width: 100%;
}
