.component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0 48px 0;

}

.card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 90vw;
    position: relative;
    border-radius: 12px;
}

.card:before {
    background: linear-gradient(45deg,
            rgba(255, 216, 137, 0) 5%,
            #EA698B 50%,
            rgba(255, 216, 137, 0) 99%);
    content: "";
    position: absolute;
    z-index: -1;
    width: 90vw;
    height: 90vw;
    border-radius: inherit;
}

.content {
    position: absolute;
    background-color: #17003f;
    z-index: 2;
    inset: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
}

.icon {
    font-size: 64px;
}
.content video {
    height: 100%;
    
}

@media only screen and (min-width: 768px) {
    .component {
        padding: 0;
        padding-bottom: 48px;
    }
}

@media only screen and (min-width: 1024px) {
    .card {
        width: 50vw;
        height: 40vw;
    }

    .card:before {
        width: 50vw;
        height: 40vw;
    }
}