.page {
    box-sizing: border-box;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content {
    width: 90vw;
    margin-top: 84px;
}

.content img {
    width: 100%;
    margin: 24px 0;
}


@media only screen and (min-width: 1024px) {
    .content {
        width: 65vw;
    }
}