.page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageTitle {
    font-size: 1.4rem;
    margin-top: 84px;

}
.wrapper {
    margin: 84px 0;
    width: 90vw;
}

.wrapper img {
    width: 100%;
}

.banner {
    width: 100%;
    margin-top: 24px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    gap: 12px;
}

.carouselMain {
    width: 85vw;
}

.title {
    font-size: 1.4rem;
}

.subtitle {
    font-size: 1rem;
}
.carouselMain img {
    width: 100%;
    height: 90%;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price {
    color: #f09dff;
}

.content button {
    background: #EA698B;
    color: #fff;
    font-family: "Mars";
    margin: 12px 0;
    padding: 8px 24px;
    cursor: pointer;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.content button:hover {
    background:#C05299;
}

.content a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
}



@media only screen and (min-width: 768px) {
    .carouselMain  {
        width: 40vw;
    }

    .content {
        align-items: flex-start;
    }
}


@media only screen and (min-width: 1024px) {
    .banner {
        width: 65vw;
    }
    .carouselMain  {
        width: 25vw;
    }

    .container {
        gap: 32px;
        margin: 42px;
    }

    
    .wrapper {
        margin: 84px 0;
        width: 60vw;
    }
    
}
