.component {
    background-image: url("../assets/bg2.png");
    background-size: cover;
    background-position: 50%;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 1.8rem;
}

.card {
    /* From https://css.glass */
    background: rgba(246, 221, 255, 0.818);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.3px);
    -webkit-backdrop-filter: blur(2.3px);
    color: #10002b;
    margin: 24px 8px;
    box-sizing: border-box;
    padding-bottom: 24px;
    height: 100%;
}

.card img {
    width: 100%;
    height: 300px;
    object-fit: cover;

}

.content {
    margin: 24px;
}

.content h2 {
    color: #973AA8;
}

.content p {
    font-size: 1.1rem;
}

.carouselMain {
    width: 90vw;
}

.buttonIcon {
    font-size: 24px;
}

@media only screen and (min-width: 768px) {

    .carouselMain {
        width: 70vw;
    }
}


@media only screen and (min-width: 1024px) {
    .title {
        margin: 24px 0;
    }
    .card {
        display: flex;
        flex-direction: row;
        height: 75vh;
        max-height: 75vh;
        padding-bottom: 0;
        align-items: center;
    }

    .card img {
        width: 25%;
        height: 100%;
    }

    .content {
        text-align: left;
        width: 75%;
    }
    .carouselMain {
        width: 90vw;
    }
}